<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>订单列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="item.label" :name="item.name" v-for="(item,index) in orderStatus"
                       :key="index"></el-tab-pane>
        </el-tabs>
        <div class="top-search">
          <div class="flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">订单编号：</div>
                <el-input v-model="search.orderNumber" placeholder="搜索"></el-input>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">商品名称：</div>
                <el-input v-model="search.commodityName" placeholder="搜索"></el-input>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">
                  <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">
                      {{ command.label }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="(item,index) in timeStatus" :command="item.value" :key="index">
                        {{ item.label }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <el-date-picker
                    v-model="datePicker"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd HH:mm:ss"
                    type="datetimerange"
                    @change="changePicker"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                ></el-date-picker>
              </div>
            </div>
          </div>
          <div class="mt-10 flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">用户留言：</div>
                <el-input v-model="search.remarks" placeholder="搜索"></el-input>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">收件人：</div>
                <el-input v-model="search.recipient" placeholder="搜索"></el-input>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">收件人手机号：</div>
                <el-input v-model="search.recipientPhone" placeholder="搜索"></el-input>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>
            <div class="flexRow">
              <el-button type="primary" v-if="orderListBatchShipment" @click="goBatchShipment">批量发货</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
              @selection-change="handleSelectionChange">
            <el-table-column
                align="center"
                type="selection"
                :selectable='handleSelect'
                width="55">
            </el-table-column>
            <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
            <el-table-column align="center" prop="orderNumber" :show-overflow-tooltip="true" label="订单编号"
                             :min-width="150"></el-table-column>
            <el-table-column align="center" label="商品信息" :min-width="200">
              <template slot-scope="scope">
                <div class="flexRow" v-if="scope.row.orderCommodityInfos && scope.row.orderCommodityInfos.length>0">
                  <img class="attrsAssociateImage" :src="scope.row.orderCommodityInfos[0].attrsAssociateImage">
                  <div class="commodityName">
                    <div class="contennt-desc">{{ scope.row.orderCommodityInfos[0].commodityName }}</div>
                    <div class="contennt-desc">{{ scope.row.orderCommodityInfos[0].attributesName }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="totalCount" label="商品数量"></el-table-column>
            <el-table-column align="center" label="订单金额">
              <template slot-scope="scope">{{
                  scope.row.payPrice ? (scope.row.payPrice.toFixed(2)) / 100 : '0.00'
                }}元
              </template>
            </el-table-column>
            <el-table-column align="center" label="使用积分">
              <template slot-scope="scope">{{
                  scope.row.payIntegral ? `${scope.row.payIntegral}分` : '0分'
                }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="买家信息" :min-width="120">
              <template slot-scope="scope">
                <div class="recipient">
                  <div>{{ scope.row.recipient }}</div>
                  <div>{{ scope.row.recipientPhone }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="recipientAddress" label="收件地址"
                             :show-overflow-tooltip="true"></el-table-column>
            <el-table-column align="center" prop="remarks" label="用户留言"
                             :show-overflow-tooltip="true">
              <template slot-scope="scope">
              {{scope.row.remarks?scope.row.remarks:'-'}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="createDate" label="下单时间" :min-width="110"></el-table-column>
            <el-table-column align="center" prop="recipientAddress" label="订单状态" min-width="120">
              <template slot-scope="scope">
                <el-tag effect="light" color="#909399" v-if="scope.row.orderStatus === 7">已取消</el-tag>
                <el-tag effect="light" v-else :color="scope.row.orderStatus | getOrderStatusType">{{
                    scope.row.orderStatus | getOrderText
                  }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <div class="border_center_left" v-if="false"
                       @click="shipments(scope.row.id)">确认发货
                  </div>
                  <div class="border_center_left" v-if="orderInfoDel" @click="del(scope.row.id)">删除</div>
                  <div class="border_center_left" @click="details(scope.row)">详情</div>
                </div>
                <div class="flex flex_row flex_ju_center" v-if="scope.row.orderStatus !== 1">
                  <div class="border_center_left" v-if="false" @click="changeStatus(scope.row.id)">订单状态</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        title="确认发货"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
    >
      <el-form v-if="shipmentsData && formDataReady" :model="shipmentsData" :rules="rules" ref="ruleForm">
        <el-form-item label="快递公司：" prop="expressName">
          <el-input v-model="shipmentsData.expressName " :disabled="true" placeholder="请输入快递公司"></el-input>
        </el-form-item>
        <el-form-item label="快递单号：(YT2173006235891)" prop="expressNumber">
          <el-input v-model="shipmentsData.expressNumber" placeholder="请输入快递单号"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submitShipments">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="订单状态"
        :close-on-click-modal="false"
        :visible.sync="dialogVisibleOrder"
        width="30%"
        :before-close="handleClose1"
    >
      <el-form v-if="eidtStatus && formDataReady" :model="eidtStatus" :rules="rules1" ref="ruleForm">
        <el-form-item label="订单状态：" prop="orderStatus">
          <el-select v-model="eidtStatus.orderStatus" placeholder="请选择">
            <el-option
                v-for="item in orderStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose1">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="editStatusBtn">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogImgVisible">
      <div class="flexRow justifyContentCenter alignItems">
        <img width="70%" :src="dialogImgUrl" alt=""></div>
    </el-dialog>
  </div>
</template>

<script>
let _this;
export default {
  data() {
    _this = this;
    const validateExpressNumber = (rule, value, callback) => {
      let reg = /^YT[0-9]{13}$/;
      if (value === '') {
        callback(new Error('请输入快递单号'));
      } else {
        if (!reg.test(value)) {
          callback(new Error('请输入YT开头+13位数字的单号'));
        }
        callback();
      }
    };
    return {
      search: {
        createDate: "",
        shipmentsDate: "",
        confirmDate: "",
        cancelDate: "",
        updateDate: "",
        orderNumber: "",
        recipient: "",
        recipientPhone: "",
        remarks: "",
        commodityName: "",
        orderStatus: null,
      },
      currentTab: "",
      input: "",
      options: [],
      tableList: [],
      value: "",
      dialogVisible: false,
      tableLoading: false,
      btnLoading: false,
      formDataReady: true,
      dialogVisibleOrder: false,
      shipmentsData: { // 确认发货
        id: '',
        expressNumber: '',
        expressName: '圆通',
        shipmentsDate: '',
        orderStatus: 3
      },
      eidtStatus: { // 订单状态
        id: '',
        orderStatus: null
      },
      rules1: {
        // orderStatus: [{required: true, message: "请选择订单状态", trigger: "change"}]
      },
      rules: {
        expressNumber: [{required: true, validator: validateExpressNumber, trigger: "blur"}],
        expressName: [{required: true, message: "请输入快递公司", trigger: "blur"}]
      },
      allState: [
        {label: '否', value: 0},
        {label: '是', value: 1}
      ],
      command: {label: '下单时间', value: 'createDate'},
      // 时间类型
      timeStatus: [
        {label: '下单时间', value: 'createDate'},
        {label: '发货时间', value: 'shipmentsDate'},
        {label: '完成时间', value: 'confirmDate'},
        {label: '取消时间', value: 'cancelDate'},
      ],
      // 订单状态：0：待付款 1：已付款，未发货 2：已付款，已发货 3：待收货 4：已收货，待评价 5：已收货，已评价 6：订单超时自动取消 7：用户主动取消
      orderStatus: [
        {label: '全部',text: '全部', name: 'all', color: '#409EFF'},
        {label: '待付款',text: '待付款', name: 'payment', value: 0, color: '#409EFF'},
        {label: '待发货',text: '待发货', name: 'shipment', value: 1, color: '#b05bff'},
        {label: '待收货',text: '待收货', name: 'takeDelivery', value: 3, color: '#F56C6C'},
        {label: '待评价',text: '待评价', name: 'evaluate', value: 4, color: '#67C23A'},
        {label: '已退款',text: '已退款', name: '8', value: 8, color: '#cbc91c'},
        {label: '已完成',text: '已完成', name: 'complete', value: 5, color: '#f988ff'},
        {label: '已取消',text: '已取消', name: 'cancel', value: 6, color: '#909399'},
      ],
      activeName: 'all', // tabs
      orderInfoDel: false,
      orderListUpd: false,
      orderListShipments: false,
      orderListBatchShipment: false,
      total: 0,
      page: 1,
      pageSize: 10,
      dialogImgVisible: false,
      dialogImgUrl: "",
      datePicker: [], // 下单时间
      countData: {},
      multipleSelection: [],

    };
  },
  filters: {
    getState(value) {
      const data = _this.allState.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].label;
      }
    },
    getOrderStatusType(value) {
      const data = _this.orderStatus.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].color;
      }
    },
    getOrderText(value) {
      const data = _this.orderStatus.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].text;
      }
    },
    getOrderStatus(value) {
      const data = _this.orderStatus.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].label;
      }
    },
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
    this.getOrderCountData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;
      const requestData = {
        page: _this.page,
        pageSize: _this.pageSize,
        search: {}
      }

      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(_this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }

      _this.$api
          .getOrderInfo(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取订单各状态数量
    getOrderCountData() {
      let _this = this;
      _this.$api.getOrderCount().then(res => {
        if (res.data.code == 200) {
          _this.countData = res.data.data;
          _this.orderStatus.forEach(item => {
            for (let key in _this.countData) {
              if (item.name == key) { //item 表示Json串中的属性，如'name'
                if (this.countData[key] != 0) {
                  item.label = item.label + '(' + _this.countData[key] + ')'
                }
              }
            }
          })
          // afterSale: null
          // all: 17
          // cancel: 9
          // complete: 1
          // evaluate: 0
          // payment: 1
          // shipment: 0
          // takeDelivery: 2
        }
      }).catch(() => {

      });
    },
    // 切换订单状态
    handleClick(tab, event) {
      // console.log(tab, event);
      let _this = this;
      _this.currentTab = tab.paneName;
      _this.orderStatus.forEach(item => {
        if (item.name === tab.paneName) {
          if (tab.paneName === 'all') {
            _this.search.orderStatus = '';
          } else {
            _this.search.orderStatus = item.value;
          }
        }
      })
      _this.page = 1;
      _this.getData();
    },
    // 搜索时间
    handleCommand(command) {
      let _this = this;
      _this.search.createDate = '';
      _this.search.shipmentsDate = '';
      _this.search.confirmDate = '';
      _this.search.cancelDate = '';
      _this.datePicker = [];
      const list = _this.timeStatus.filter(item => item.value === command);
      if (list) {
        _this.command = list[0]
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSelect(val) {
      let _this = this;
      if (val.orderStatus === 1) {
        return true
      }
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));
      list.forEach((v) => {
        if (v == "orderlist:del") {
          _this.orderListDel = true;
        }
        if (v == "orderlist:upd") {
          _this.orderListUpd = true;
        }
        if (v == "orderlist:shipments") {
          _this.orderListShipments = true;
        }
        if (v == "orderlist:batchShipment") {
          _this.orderListBatchShipment = true;
        }
      })
    },
    // 下单时间
    changePicker(e) {
      let _this = this;
      _this.search.createDate = '';
      _this.search.shipmentsDate = '';
      _this.search.confirmDate = '';
      _this.search.cancelDate = '';
      if (_this.command.value === 'createDate') {
        _this.search.createDate = e[0];
      }
      if (_this.command.value === 'shipmentsDate') {
        _this.search.shipmentsDate = e[0];
      }
      if (_this.command.value === 'confirmDate') {
        _this.search.confirmDate = e[0];
      }
      if (_this.command.value === 'cancelDate') {
        _this.search.cancelDate = e[0];
      }
      _this.search.updateDate = e[1];
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;

      _this.search = {
        createDate: "",
        shipmentsDate: "",
        confirmDate: "",
        cancelDate: "",
        updateDate: "",
        orderNumber: "",
        recipient: "",
        recipientPhone: "",
        remarks: "",
        commodityName: "",
        orderStatus: null,
      }
      if (_this.currentTab === 'all') {
        _this.search.orderStatus = '';
      } else {
        _this.search.orderStatus = Number(_this.currentTab);
      }
      _this.page = 1;
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },
    // 浏览图片
    handleSee(e) {
      let _this = this;
      _this.dialogImgUrl = e;
      _this.dialogImgVisible = true;
    },

    goBatchShipment() {
      let _this = this;
      if(_this.multipleSelection.length === 0) {
        _this.$message.error('请选择待发货的订单');
        return
      }
      _this.$router.push("/batchShipment?data="+ encodeURIComponent(JSON.stringify(_this.multipleSelection)));
    },
    details(e) {
      let _this = this;
      _this.$router.push(
          "/orderDetails?orderId=" + e.id
      );
    },
    edit(e) {
      let _this = this;
      _this.$router.push(
          "/addCommodityInfo?type=edit&goodsId=" + e.id
      );
    },
    // 获取当前时间 格式：yyyy-MM-dd HH:MM:SS
    getCurrentTime() {
      let _this = this;
      let date = new Date();//当前时间
      let month = _this.zeroFill(date.getMonth() + 1);//月
      let day = _this.zeroFill(date.getDate());//日
      let hour = _this.zeroFill(date.getHours());//时
      let minute = _this.zeroFill(date.getMinutes());//分
      let second = _this.zeroFill(date.getSeconds());//秒

      //当前时间
      let curTime = date.getFullYear() + '-' + month + '-' + day
          + " " + hour + ':' + minute + ':' + second;

      return curTime;
    },
    // 补零
    zeroFill(i) {
      if (i >= 0 && i <= 9) {
        return '0' + i;
      } else {
        return i;
      }
    },
    // 确认发货
    shipments(id) {
      let _this = this;
      _this.dialogVisible = true;
      _this.shipmentsData = {
        id: id,
        expressNumber: '',
        expressName: '圆通',
        orderStatus: 3
      };
      _this.handleInitForm();
    },
    // 确认发货-提交
    submitShipments() {
      let _this = this;
      _this.btnLoading = true;

      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          //YT2173006235891
          _this.shipmentsData.shipmentsDate = _this.getCurrentTime();

          _this.$api.editOrderInfo(_this.shipmentsData).then(res => {
            if (res.data.code == 200) {
              _this.$message.success("操作成功");
              _this.btnLoading = false;
              _this.handleClose();
              _this.getData();
            }
          }).catch(() => {
            _this.btnLoading = false;
          });
        } else {
          _this.btnLoading = false;
        }
      });
    },
    // 修改订单状态
    changeStatus(id) {
      let _this = this;
      _this.dialogVisibleOrder = true;
      _this.eidtStatus = {
        id,
        orderStatus: null
      };
      _this.handleInitForm();
    },
    editStatusBtn() {
      let _this = this;
      _this.btnLoading = true;
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          _this.$api.editOrderInfo(_this.eidtStatus).then(res => {
            if (res.data.code == 200) {
              _this.$message.success("操作成功");
              _this.btnLoading = false;
              _this.handleClose1();
              _this.getData();
            }
          }).catch(() => {
            _this.btnLoading = false;
          });
        } else {
          _this.btnLoading = false;
        }
      });
    },
    handleClose1() {
      let _this = this;
      _this.eidtStatus = {
        id: '',
        orderStatus: null
      };
      _this.handleInitForm();
      _this.dialogVisibleOrder = false;
    },
    // 删除
    del(id) {
      let _this = this;
      _this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.delOrderInfo({id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success('删除成功');
                  _this.getData();
                } else {
                  _this.$message.error('删除失败');
                }
              }
            });
          })
          .catch(() => {
          });
    },

    // 关闭确认发货
    handleClose() {
      let _this = this;
      _this.shipmentsData = {
        id: '',
        expressNumber: '',
        expressName: '圆通',
        orderStatus: 3
      };
      _this.handleInitForm();
      _this.dialogVisible = false;
    },
    // 初始化表单
    handleInitForm() {
      let _this = this;
      _this.formDataReady = false;
      _this.$nextTick(() => {
        _this.formDataReady = true;
      });
    },
  }
};
</script>
<style scoped lang="less">
.between-text {
  margin: 0 10px;
}

/deep/ .el-form-item {
  width: 100%;
}

/deep/ .el-tag {
  color: #fff !important;
  border: none;
}

.attrsAssociateImage {
  width: 50px;
  height: 50px;
}

.commodityName {
  margin-left: 10px;
  text-align: left;
}

.recipient {
  text-align: left;
}

.el-dropdown-link {
  cursor: pointer;
  margin-right: 10px;
}

.el-icon-arrow-down {
  font-size: 12px;
}
/deep/.el-table__header-wrapper  .el-checkbox{
  display:none
}
</style>

